import React from 'react';

import Anchor from '../landing_page/components/anchor';
import HeaderLogo from '../landing_page/components/header_logo';

function FourZeroFourHeader() {
  const { kairos_base_url, logo } = window.dataFromRuby;

  return (
    <div className='landing_page__header landing_page__header--stickyHeader'>
      <div className='landing_page__header-container u-pt--n u-pb--n'>
        <div className='landing_page__header-left' style={{ display: 'flex', verticalAlign: 'middle' }}>
          <HeaderLogo kairos_base_url={kairos_base_url} link_to_corporate_site={false} logo={logo} />
        </div>
        <div className='landing_page__header-right' style={{ display: 'flex' }}>
          <Anchor
            anchorHref={`${kairos_base_url}/login`}
            anchorText='Log in'
            ariaLabel='Log in'
            classNames='ev-text-action-primary--sm u-4mt--s u-4mb--s'
            section='header'
            event='login'
            type='button'
          />
        </div>
      </div>
    </div>
  );
}

export default FourZeroFourHeader;
